.signin {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-color: var(--background);
}
.signin .login-form {
  height: auto;
  margin: 20px 0;
  /* border: 1px #ddd solid; */
  box-shadow: var(--boxShadow);
  background-color: var(--cardColor);
  padding: 20px;
  border-radius: 2px;
  width: 45%;
}
.signin .login-form img {
  margin: 0 auto;
  display: flex;
}

.signin .login-form h2 {
  font-weight: bold;
  text-align: left;
  color: var(--text);
  margin-bottom: 10px;
}
.signin .login-form button {
  width: 100%;
  height: 45px;
  border: none;
  font-weight: bold;
  letter-spacing: 1px;
  background-color: var(--primary);
}
/* .signin .for-signup {
  text-align: center;
  width: 100%;
}
.signin .for-signup button {
  width: 100%;
  height: 40px;
  margin: 10px auto;
  border-color: #adb1b8 #a2a6ac #8d9096;
  border: 1px solid;
  background: linear-gradient(to bottom, #f7f8fa, #e7e9ec);
}

.signin .for-signup .signin-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 47%;
}
.signin .for-signup .signin-btn svg {
  margin-right: 10px;
  font-size: 20px;
}
.signin .for-signup .signin-gf {
  display: flex;
} */

/* forget password  */
/* .signin-forget {
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  text-align: center;
}
.signin-forget .forget-inner {
  width: 40%;
  height: 200px;
}
.signin-forget img,
.signin-forget h4,
.signup img,
.signup h4 {
  margin-bottom: 20px;
}
.for-signup h4 {
  margin: 10px 0;
}

.signin-forget p {
  margin-top: 20px;
  letter-spacing: 1px;
}
.signin-forget button {
  width: 100px;
  display: block;
  margin: 20px auto 0 auto;
} */

/* signup */

/* .signup {
  margin: 30px auto;
} */

/* media queries */

@media (max-width: 770px) {
  .signin .login-form {
    width: 60%;
  }
}
@media (max-width: 550px) {
  .signin .login-form {
    width: 90%;
  }
}
