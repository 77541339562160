.annoucement-container .birthday .date-box {
  position: absolute;
  background-color: #ec7063;
  width: 100%;
  height: -webkit-fill-available;
  top: 0;
  right: 0;
  margin: 0;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: var(--text);
  padding-left: 10px;
}

.annoucement-container .birthday {
  padding: 0px !important;
}

.annoucement-container .birthday li {
  background-color: #ec7063;
  color: var(--text) !important;
}

.annoucement-container .ant-picker-calendar .ant-radio-group {
  display: none !important;
}
.annoucement-container .ant-picker-calendar-header {
  padding-right: 1rem;
}

.annoucement-container .notice-board {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.annoucement-container .notice-board .item {
  background-color: var(--cardColor);
  color: var(--text);
  padding: 4px 10px;
  border-radius: 4px;
  margin-bottom: 6px;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: var(--cardShadow);
  position: relative;
}

.annoucement-container .notice-board .item .notice {
  margin-top: 4px;
  padding-bottom: 4px;
  font-size: 16px;
  width: 90%;
}

.annoucement-container .notice-board .item .date {
  font-size: 12px;
  width: 100%;
  text-align: left;
  padding: 0;
  margin: 0;
  color: gray;
}
.annoucement-container .notice-board .item .icon {
  color: var(--text);
  border-radius: 50%;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  font-size: 20px;
}
.annoucement-container .notice-board .item .icon:hover {
  color: var(--red);
}
