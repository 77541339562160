.add-accessories {
  width: 100%;
  padding-bottom: 1rem;
  padding-left: 0.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.add-accessories .addAccessoriesBtn {
  padding: 0 0.7rem;
  padding-bottom: 2px;
  height: 33px;
  border-radius: 2px !important;
}
