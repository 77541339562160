.expense-main h1,
.expense-main h2,
.expense-main h3,
.expense-main p {
  color: var(--text);
  margin-top: 0.6rem;
}
.expense-main h3 {
  margin-bottom: 40px;
}
.expense-main .ant-divider-horizontal {
  border-color: var(--text);
}
.expense-card {
  display: flex;
  height: max-content;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  padding: 20px;
  background-color: var(--cardColor);
}
