.accessories-card {
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  padding: 20px;
  margin: 10px;
  min-height: 190px;
  background-color: var(--cardColor);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.accessories-card .info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.accessories-card .info p {
  color: hsl(229, 6%, 66%);
  font-size: 12px;
  width: 100%;
  margin-top: 4px;
  min-height: 20px;
}
