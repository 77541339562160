.ant-menu-dark .ant-menu-sub {
  background: var(--sidebar);
}

.ant-layout-sider::-webkit-scrollbar {
  display: none;
}
.themeSwitch {
}
.ant-switch-checked {
  background-color: var(--primary) !important;
}
.ant-switch-handle {
  top: 3px !important;
  width: 15px !important;
  height: 15px !important;
  left: 7px;
}
.ant-switch-checked .ant-switch-inner {
  margin-left: 3px !important;
}
.ant-drawer-content-wrapper {
  width: 100% !important;
}
.v-layout .company-logo {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 10vh;
  margin-bottom: 10px;
}
.v-layout .company-logo img {
  width: 70%;
}
.v-layout .company-title {
  font-size: 22px;
  color: var(--text) !important;
  text-transform: uppercase;
}

/* .v-layout .mainHeader{
  display: flex;
  justify-content: space-between;
  align-items: center;
} */

.v-layout .headerLft {
  display: flex;
  align-items: center;
}
.v-layout .notiBtn {
  color: var(--text);
}

.v-layout .notiIcon {
  font-size: 28px;
  color: var(--text);
  width: max-content;
}
.v-layout .notiIcon:hover {
  cursor: pointer;
  width: max-content;
}

.v-layout .notiCount {
  position: absolute;
  right: 120px;
  top: 20px;
  z-index: 100;
  background-color: rgb(253, 68, 68);
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 7px;
  border-radius: 50%;
  font-weight: 1000;
  font-size: 10px;
  color: white !important;
}

.v-layout .notiComp {
  background-color: var(--background);
  /* background-color: white; */
  border: 2px solid gray;
  color: var(--text);
  position: absolute;
  right: 100px;
  top: 60px;
  max-width: 300px;
  max-height: 400px;
  min-height: 400px;
  min-width: 300px;
  padding: 10px;
  line-height: 20px !important;
  overflow: auto;
}
.v-layout .notiHeader {
  height: 30px;
  border-bottom: 1px solid gray;
  padding: 5px;
}
.v-layout .notiHeader span {
  color: var(--text);
}

.v-layout .notiFooter {
  margin-top: 10px;
  text-align: center;
}

.v-layout .notiCard {
  height: auto;
  max-height: 120px;
  min-height: 80px;
  border-bottom: 1px solid gray;
  padding: 5px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  /* width: 100%; */
}

.v-layout .message {
  color: var(--text);
  font-size: 12px;
  width: 70%;
}
.v-layout .date {
  color: var(--text);
  font-size: 10px;
  width: 30%;
  text-align: right;
  padding-left: 5px;
}

.v-layout .notiCard button {
  font-size: 9px;
  font-weight: 500;
  width: max-content;
  height: fit-content;
  margin-top: 10px;
  outline: none !important;
}

.v-layout .notiCard .ant-btn:focus {
  outline: none !important;
  border: none !important;
}
