.ant-table {
  background-color: var(--cardColor) !important;
  color: var(--text) !important;
}
.ant-table-thead > tr > th {
  background-color: var(--cardColor) !important;
  color: var(--text) !important;
}
.ant-spin-dot {
  font-size: 40px;
}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
  position: absolute;
  top: 54%;
  left: 49%;
}
.ant-table .ant-table-expanded-row-fixed {
  min-height: 300px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-empty-img-simple-path,
.ant-empty-img-simple-ellipse {
  fill: var(--tableHeader) !important;
}
.ant-empty-description {
  color: var(--text) !important;
}
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background-color: var(--cardColor) !important;
}
.ant-pagination-prev .ant-pagination-item-link {
  background-color: transparent !important;
  color: var(--text) !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-pagination-item {
  background-color: transparent !important;
}
.ant-pagination-item a {
  color: var(--text) !important;
}
.ant-pagination-next .ant-pagination-item-link {
  background-color: transparent !important;
  color: var(--text) !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-pagination-item-active {
  background-color: transparent !important;
  color: var(--text) !important;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: transparent !important;
  color: var(--text) !important;
}
.ant-select-arrow {
  color: var(--text) !important;
}
.ant-pagination-options {
  margin-right: 1rem;
}
/* .ant-tabs-top > .ant-tabs-nav::before , */
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  border-color: var(--borderLight) !important;
}

.action-div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.action-div .icon {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  padding: 4px;
  color: var(--primary);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  cursor: pointer;
}
.action-div .icon:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.action-div .delete {
  color: var(--red);
}
