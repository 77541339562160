.home .project-stats h4 {
  /* text-decoration: underline; */
  color: black !important;
  font-size: 16px;
}
.home .project-stats {
  margin: 20px 0 50px 0;
}
.project-table .action-buttons button {
  margin: 0 10px;
}
.project-table .ant-table-thead > tr > th,
.project-table .ant-table-tbody > tr > td {
  text-align: center !important;
}
