.leave-container .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.leave-container .header h4 {
  color: var(--text);
}
.leave-container .leave {
  width: 100%;
  padding: 0px !important;
}

.leave-container .leave .date-box {
  position: absolute;
  /* background-color: #ec7063; */
  width: 100%;
  height: -webkit-fill-available;
  top: 0;
  right: 0;
  margin: 0;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: var(--text);
  padding-left: 10px;
}
.leave-container .leave .date-box p {
  font-weight: 500;
}
.leave-container .leave .pending {
  background-color: #aed6f1bd;
  color: black;
}

.leave-container .leave .active {
  background-color: #58d68c;
  color: black;
}

.leave-container .leave .rejected {
  background-color: #f5b7b1;
  color: black;
}

.leave-container .ant-picker-calendar .ant-radio-group {
  display: none !important;
}
.leave-container .ant-picker-calendar-header {
  padding-right: 1rem;
}

.decision-div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.decision-div .greentag:hover {
  background-color: #58d68c52;
  cursor: pointer;
}

.decision-div .redtag:hover {
  background-color: #f5b7b150;
  cursor: pointer;
}
