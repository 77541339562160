.doc-view .backArrow {
  cursor: pointer;
  margin-bottom: 1rem;
}
.doc-view .backArrow:hover {
  color: var(--primary);
}
.doc-view .main {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.doc-view .document-div {
  width: 150px;
  height: 150px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px dashed rgb(199, 199, 199);
}
.doc-view .document-div p {
  text-align: center;
  color: var(--text);
  font-size: 12px;
}
.doc-view .document-div .file-icon {
  width: 70px;
  height: 70px;
  color: gray;
  margin-top: 10px;
}
.doc-view .document-div .download-icon {
  position: absolute;
  top: 6px;
  right: 6px;
  color: rgb(184, 184, 184);
  border-radius: 50%;
  width: 26px;
  height: 26px;
  padding: 4px;
}

.doc-view .document-div .download-icon:hover {
  color: var(--primary);
}
.doc-view .document-div .date {
  position: absolute;
  top: 10px;
  left: 4px;
  color: var(--text);
  font-size: 11px;
  width: max-content;
}

.docViewerModal .ant-modal-content {
  height: 100%;
}
