* {
  /* scroll-behavior: smooth; */
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  background-color: #eaeaea;
}

.swal-text {
  text-align: center !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  letter-spacing: 1px;
  margin: 0;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  /* border-radius: 20px; */
}
/* Track */
::-webkit-scrollbar-track {
  background: #555;

  /* border-radius: 20px; */
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 20px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--primary);
}

.general-header {
  background-color: #fff;
  box-shadow: var(--boxShadow);
  padding: 17px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* position: fixed; */
  width: 100%;
  z-index: 100;
  top: 0;
}
.general-header .Buttons {
  width: auto;
}
.general-header .Buttons button {
  border-color: var(--primary-color);
  color: var(--primary-color);
}
.general-header .Buttons button:first-child {
  margin-right: 10px;
}
.general-header .vendor-info {
  /* display: flex;
  align-items: center; */
  width: 30%;
}
.general-header a {
  width: 50%;
}
.general-header .vendor-info p {
  margin-bottom: 0;
  margin-left: 10px;
  text-transform: capitalize;
  font-size: 17px;
  font-weight: bold;
}

.general-header h2 {
  color: var(--secondary-color);
  margin: 0;
  letter-spacing: 2px;
  font-weight: bold;
}

.empty {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.d-flex {
  display: flex;
}
.space-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
  justify-content: center;
}
.m-20 {
  margin: 20px 0;
}

/* .infinityLoader {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--loaderBg);
  z-index: 10;
  backdrop-filter: blur(0.8px);
}
.infinityLoader img {
  z-index: 11;
} */
.editBtn {
  font-size: 20px;
  cursor: pointer;
}
.editBtn:hover {
  color: var(--primary);
}
.Heading {
  font-size: 20px;
  color: var(--text);
}

.redtag {
  background-color: #f5b7b133;
  color: #e74c3c;
  padding: 2px 8px;
  border-radius: 4px;
  font-weight: bold;
  width: max-content;
  margin: 0;
}
.greentag {
  background-color: #58d68c33;
  color: #52be80;
  padding: 2px 8px;
  border-radius: 4px;
  font-weight: bold;
  width: max-content;
  margin: 0;
}
.bluetag {
  background-color: #aed6f133;
  color: #3699ff;
  padding: 2px 8px;
  border-radius: 4px;
  font-weight: bold;
  width: max-content;
  margin: 0;
}
.yellowtag {
  background-color: #f4d03f33;
  color: #f39c12;
  padding: 2px 8px;
  border-radius: 4px;
  font-weight: bold;
  width: max-content;
  margin: 0;
}

.refundedTag {
  font-weight: bold;
  color: #f39c12;
}
.pendingTag {
  font-weight: bold;
  color: #3699ff;
}
.paidTag {
  font-weight: bold;
  color: #58d68d;
}
.unPaidTag {
  font-weight: bold;
  color: #e74c3c;
}

@media (max-width: 550px) {
  .general-header {
    flex-direction: column;
  }
  .general-header h2 {
    font-size: 20px;
    margin: 20px 0;
  }
  .general-header {
    align-items: flex-start;
  }
  .general-header .Buttons {
    align-items: flex-end;
    margin-left: auto;
  }
  .general-header .vendor-info p {
    font-size: 13px;
  }
  .infinityLoader img {
    height: 120px;
  }
}

.calendar {
  padding: 5rem;
  display: flex;
  align-items: center;
  gap: 2rem;
  flex-wrap: wrap;
  width: 100%;
  background-color: white;
}
.calendar .card {
  background-color: rgb(247, 247, 247);
  padding: 5rem;
  position: relative;
  border-radius: 10px;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}
.calendar .card h1 {
  position: absolute;
  top: 20px;
  left: 20px;
  color: gray;
}

.calendar .card p {
  position: absolute;
  top: 55px;
  left: 20px;
  color: gray;
}

.empty {
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
