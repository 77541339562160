.checkin-btn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* padding: 20px; */
  margin: 20px 0;
}

.checkin-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.checkin-head h2 {
  color: var(--text);
  font-weight: bold;
}

.checkin-head .ant-picker {
  width: 300px !important;
}

.toggle-checkin-history-btn-container {
  margin: 1rem 0;
}

.toggle-checkin-history-btn {
  color: #fff;
  font-size: 1.2rem;
  cursor: pointer;
  font-weight: 500;
  transition: 0.3s;
  display: flex;
  align-items: center;
  gap: 10px;
}

.toggle-checkin-history-btn:hover {
  color: #f55139;
}
