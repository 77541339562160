.admin-employee-container {
  padding-left: 0.5rem;
}
.admin-employee-container .header {
  width: 100%;
  display: flex;
  padding-bottom: 1rem;
  gap: 20px;
}
.admin-employee-container .header .addEmployeeBtn {
  padding: 0 0.7rem;
  padding-bottom: 2px;
  height: 33px;
  border-radius: 2px !important;
}
.admin-employee-container .header .addEmployeeBtn span {
  font-size: 12px !important;
}

.admin-employee-container .ant-tabs-nav-list {
  color: var(--text);
}

@media (max-width: 1000px) {
  .admin-employee-container .header {
    flex-direction: column;
  }
}
