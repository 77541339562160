.history-container .backArrow {
  cursor: pointer;
  margin-bottom: 1rem;
}
.history-container .backArrow:hover {
  color: var(--primary);
}
.history-container .main {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.history-container .main .timeline {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.history-container .main .timeline .tail {
  height: 24px;
  width: 1.4px;
  background-color: var(--borderLight);
  margin-bottom: 4px;
}
.history-container .main .timeline .date {
  width: unset;
  text-align: center;
  margin-bottom: 8px;
  background-color: var(--dateCard);
  padding: 4px 8px;
  border-radius: 4px;
  color: var(--dateText);
  box-shadow: var(--tabShadow);
  font-size: 12px;
}
.history-container .main .timeline .content {
  width: unset;
  text-align: left;
  margin-bottom: 4px;
  background-color: var(--cardColor);
  padding: 8px 16px;
  border-radius: 8px;
  color: var(--text);
  box-shadow: var(--tabShadow);
  font-size: 13px;
}
.history-container .main .timeline .content p {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 2px;
}
.history-container .main .timeline .content p .icon {
  color: var(--text);
  font-size: 6px;
}
