.document-list {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.document-list .document-div {
  width: 150px;
  height: 150px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px dashed rgb(199, 199, 199);
}
.document-list .document-div p {
  text-align: center;
  color: var(--text);
  font-size: 12px;
}
.document-list .document-div .file-icon {
  width: 70px;
  height: 70px;
  color: gray;
}
.document-list .document-div .download-icon {
  position: absolute;
  top: 6px;
  right: 6px;
  color: rgb(184, 184, 184);
  border-radius: 50%;
  width: 26px;
  height: 26px;
  padding: 4px;
}
.document-list .document-div .download-icon:hover {
  color: var(--primary);
}
.document-list .document-div .icon {
  position: absolute;
  top: 6px;
  right: 36px;
  color: rgb(184, 184, 184);
  border-radius: 50%;
  width: 26px;
  height: 26px;
  padding: 3px;
  border: 1px dashed rgb(199, 199, 199);
  display: none;
}
.document-list .document-div:hover .icon {
  display: block;
}
.document-list .document-div:hover .icon:hover {
  color: var(--primary);
  border-color: var(--primary);
}

.document-list .Upload-div .ant-upload-picture-card-wrapper {
  width: 100%;
}
.document-list .ant-upload-list-picture-card .ant-upload-list-item {
  width: 150px !important;
  height: 150px !important;
}

.document-list .Upload-div .ant-upload .ant-upload-select-picture-card {
  width: 150px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 150px !important;
}

.document-list .Upload-div h4 {
  font-size: 12px;
  color: var(--primary);
}
.document-list .Upload-div .icon {
  color: var(--primary);
}
.document-list .ant-upload {
  width: 150px !important;
  height: 150px !important;
  border-radius: 0 !important;
}
.document-list
  .ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload.ant-upload-select
  > .ant-upload {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.document-list .ant-upload-wrapper.ant-upload-picture-card-wrapper {
  width: max-content !important;
}
.document-list .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
  margin-bottom: 0 !important;
  margin-inline-end: 0 !important;
}
