.settings-cont {
  color: var(--text);
  /* background-color: red; */
}

.settings-cont .ant-tabs-nav-list {
  color: var(--text);
}
.subscription-cont > h3 {
  color: var(--text);
  margin-top: 10px;
}
.subs-plan-top > div > div > h4 {
  color: var(--text);
}
.text {
  color: var(--text) !important;
}
.rows {
  margin-top: 10px;
}

.subs-plan-bottom {
  width: 100%;
  margin-top: 30px;
}

.textCenter {
  margin-top: 10px;
  /* display: flex !important; */
  /* align-items: center !important; */
}
