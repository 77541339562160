.attendance-container .ant-tabs-tab {
  font-size: 16px;
  font-weight: 500;
  color: var(--text);
}

.attendance-container h1,
.attendance-container h4,
.attendance-container h2,
.attendance-container h3 {
  color: var(--text);
  margin-bottom: 10px;
}
