.react-kanban-column-header.react-kanban-column-header {
  color: var(--text);
  font-size: 18px;
  font-weight: 500;
  background-color: rgba(140, 135, 135, 0.15);
  letter-spacing: 1px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 10px;
  border-radius: 5px;
  border-top: 3px solid var(--orange);
  margin-bottom: 10px;
}
.react-kanban-column.react-kanban-column {
  padding: 0px;
  margin: 0 10px;
  width: 300px;
  background-color: transparent;
  padding-bottom: 10px;
}

.react-kanban-column.react-kanban-column :nth-child(2) div div {
  width: 100%;
}

.react-kanban-card.react-kanban-card {
  font-weight: 400;
  color: var(--text);
  border-radius: 0px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  background-color: rgba(140, 135, 135, 0.15);
  font-size: 13px;
  line-height: 15px;
  min-width: initial !important;
  max-width: initial !important;
  width: 100% !important;
  margin-bottom: 0px;
  margin-top: 20px;
}
.react-kanban-card__title.react-kanban-card__title {
  padding: 0;
  padding-bottom: 5px;
}
